<template>
  <div v-if="coupons">
    <div v-if="!isCreatingCoupon && !isUpdatingCoupon">
      <div class="flex justify-between items-center">
        <h1>Coupons</h1>
        <base-button
          class="btn text font-weight-medium sm:p-4 p-3 primary"
          @click="isCreatingCoupon = true"
          >New Coupon</base-button
        >
      </div>

      <table class="table-fixed w-full">
        <tr>
          <th class="text-left border-b-2">Code</th>
          <th class="text-center border-b-2">Amount</th>
          <th class="text-left border-b-2">Quota</th>
          <th class="text-left border-b-2">Duration</th>
          <th class="text-left border-b-2">Expires</th>
          <th class="text-left border-b-2">New users coupon?</th>
          <th class="border-b-2"></th>
          <th class="border-b-2"></th>
        </tr>
        <tr class="border-b-2 py-12" v-for="coupon in coupons" v-bind:key="coupon.code">
          <td>
            {{ coupon.code }}
          </td>
          <td class="text-center" v-if="coupon.discountType === 'percentage'">
            {{ Number(coupon.discountAmount) * 100 }}%
          </td>
          <td class="text-center" v-else>
            {{ Number(coupon.discountAmount).toFixed(2) }} {{ coupon.currency }}
          </td>
          <td>{{ coupon.timesUsed || 0 }}/{{ coupon.allowedUses }}</td>
          <td v-if="coupon.type === 'interval'">{{ coupon.durationInMonths }} month(s)</td>
          <td v-else>{{ coupon.type === 'recurring' ? 'Recurring' : 'One time' }}</td>
          <td>{{ coupon.expiresIn || 'Never' }}</td>
          <td>{{ coupon.isNewUsersCoupon ? 'Yes' : 'No' }}</td>
          <td>
            <base-button
              class="btn text font-weight-medium sm:p-4 p-3 primary"
              @click="setCouponToUpdate(coupon)"
              >Update</base-button
            >
          </td>
          <td>
            <base-button
              class="btn text font-weight-medium sm:p-4 p-3 delete"
              @click="deleteCoupon(coupon.code, coupon.plans)"
              >Delete</base-button
            >
          </td>
        </tr>
      </table>
    </div>

    <div v-else>
      <div class="flex justify-between items-center">
        <h1>{{ isCreatingCoupon ? 'Create Coupon' : 'Update Coupon' }}</h1>
        <div class="flex space-x-2">
          <base-button
            class="btn text font-weight-medium sm:p-4 p-3 secondary"
            @click="returnToCouponsList"
            >Cancel</base-button
          >
          <base-button
            :disabled="!canFormBeSubmitted"
            v-if="isCreatingCoupon"
            :loading="isSaving"
            @click="createCoupon"
            class="btn text font-weight-medium sm:p-4 p-3 primary"
            >Create Coupon</base-button
          >
          <base-button
            :disabled="!canFormBeSubmitted"
            v-else
            :loading="isSaving"
            @click="updateCoupon"
            class="btn text font-weight-medium sm:p-4 p-3 primary"
            >Update Coupon</base-button
          >
        </div>
      </div>

      <div class="space-y-4">
        <div class="flex space-x-2 items-center">
          <label for="code" class="w-24">Code</label>
          <input
            class="w-64"
            id="code"
            placeholder="Coupon code"
            type="text"
            v-model="couponToSave.code"
          />
        </div>

        <div class="flex space-x-2 items-center">
          <label class="w-24" for="type">Type</label>
          <select id="type" class="w-64" v-model="couponToSave.type">
            <option value="recurring">Recurring</option>
            <option value="oneTime">One time</option>
            <option value="interval">Multiple months</option>
          </select>
        </div>

        <div v-if="couponToSave.type === 'interval'" class="flex space-x-2 items-center">
          <label for="duration" class="w-24">Number of months</label>
          <input class="w-64" id="duration" type="number" v-model="couponToSave.durationInMonths" />
        </div>

        <div class="flex space-x-2 items-center">
          <label class="w-24" for="discountType">Discount type</label>
          <select
            :disabled="isUpdatingCoupon"
            id="discountType"
            class="w-64"
            v-model="couponToSave.discountType"
          >
            <option value="percentage">Percentage</option>
            <option value="flat">Flat</option>
          </select>
        </div>

        <div class="flex space-x-2 items-center">
          <label for="discountAmount" class="w-24">Discount amount</label>
          <input
            class="w-64"
            id="discountAmount"
            type="number"
            v-model="couponToSave.discountAmount"
          />
        </div>

        <div class="flex space-x-2 items-center">
          <label for="allowedUses" class="w-24">Allowed uses</label>
          <input class="w-64" id="allowedUses" type="number" v-model="couponToSave.allowedUses" />
        </div>

        <div class="flex space-x-2 items-center">
          <label for="expiresIn" class="w-24">Expires in</label>
          <input class="w-64" id="expiresIn" type="date" v-model="couponToSave.expiresIn" />
        </div>

        <div class="space-y-2">
          <span>Applicable Plans</span>
          <div v-for="(value, name) of plans" :key="value">
            <label class="cursor-pointer space-x-2">
              <input
                class="cursor-pointer"
                type="checkbox"
                :value="value"
                v-model="couponToSave.plans"
              />
              <span>{{ name }}</span>
            </label>
          </div>
        </div>

        <div class="flex space-x-2 items-baseline">
          <label for="description" class="w-24">Description</label>
          <textarea
            :disabled="isUpdatingCoupon"
            v-model="couponToSave.description"
            class="form-control textarea"
            id="description"
            cols="30"
            rows="3"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import controller from '@/controller';
  import dao from '@/dao';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  const config = require('@/config');

  export default {
    computed: {
      ...mapGetters({
        currentUser: 'getCurrentUser',
      }),
      canFormBeSubmitted() {
        return (
          this.couponToSave.code.length >= 5 &&
          this.couponToSave.code.length <= 300 &&
          Number(this.couponToSave.discountAmount) >= 1 &&
          Number(this.couponToSave.discountAmount) <= 100 &&
          (this.couponToSave.type !== 'interval' ||
            (Number(this.couponToSave.durationInMonths) >= 1 &&
              Number(this.couponToSave.durationInMonths) <= 24 &&
              Object.entries(this.plans)
                .filter((plan) => this.couponToSave.plans.includes(plan[1]))
                .every(([planName]) => !planName.includes('Yearly')))) &&
          this.couponToSave.plans.length > 0 &&
          this.couponToSave.description.length <= 300
        );
      },
    },
    mounted() {
      this.assignPlans();
      this.fetchCoupons();
    },
    data() {
      return {
        isCreatingCoupon: false,
        isUpdatingCoupon: false,
        isSaving: false,
        coupons: null,
        couponToSave: {
          code: '',
          type: 'recurring',
          discountType: 'percentage',
          discountAmount: 10,
          allowedUses: 1,
          expiresIn: '',
          plans: [],
          description: '',
        },
        plans: {},
      };
    },
    methods: {
      returnToCouponsList() {
        this.isUpdatingCoupon = false;
        this.isCreatingCoupon = false;

        this.couponToSave = {
          code: '',
          type: 'recurring',
          discountType: 'percentage',
          discountAmount: 10,
          allowedUses: 1,
          expiresIn: '',
          plans: [],
          description: '',
        };
      },
      setCouponToUpdate(coupon) {
        this.couponToSave = {
          ...coupon,
          oldCode: coupon.code,
          oldType: coupon.type,
          discountAmount:
            coupon.discountType === 'flat'
              ? Number(coupon.discountAmount)
              : Number(coupon.discountAmount) * 100,
          description: coupon.description || '',
        };
        this.isUpdatingCoupon = true;
      },
      async fetchCoupons() {
        const dbCoupons = await dao.appSettings.fetchCoupons();

        const couponsPromises = Object.values(config.paddleInfo.plans).map((plan) =>
          controller.fetchCoupons(this.currentUser, plan)
        );

        const responses = await Promise.all(couponsPromises);

        const groupedCoupons = {};

        responses.forEach((response) => {
          response.coupons.forEach((coupon) => {
            if (!groupedCoupons[coupon.coupon]) {
              groupedCoupons[coupon.coupon] = { ...coupon, plans: [response.productId] };
            } else {
              groupedCoupons[coupon.coupon].plans.push(response.productId);
            }
          });
        });

        this.coupons = Object.values(groupedCoupons).map((coupon) => {
          const dbCoupon = dbCoupons[coupon.coupon];
          const couponType = (() => {
            if (dbCoupon) {
              return 'interval';
            } else if (coupon.is_recurring) {
              return 'recurring';
            } else {
              return 'oneTime';
            }
          })();

          return {
            code: coupon.coupon,
            discountType: coupon.discount_type,
            discountAmount: coupon.discount_amount,
            description: coupon.description,
            allowedUses: coupon.allowed_uses,
            expiresIn: coupon.expires ? coupon.expires.split(' ')[0] : null,
            type: couponType,
            plans: coupon.plans,
            timesUsed: coupon.times_used,
            currency: coupon.discount_currency,
            ...(dbCoupon ? { durationInMonths: dbCoupon.durationInMonths } : {}),
            isNewUsersCoupon: coupon.description
              ? coupon.description.toLowerCase().includes('group:newusers')
              : false,
          };
        });
      },
      async updateCoupon() {
        this.isSaving = true;
        try {
          await controller.updateCoupon(this.currentUser, this.couponToSave);

          if (
            this.couponToSave.oldCode !== this.couponToSave.code ||
            this.couponToSave.oldType !== this.couponToSave.type
          ) {
            await dao.appSettings.deleteCoupon(this.couponToSave.oldCode);
          }

          if (this.couponToSave.type === 'interval') {
            const { code, durationInMonths, discountAmount, discountType } = this.couponToSave;
            await dao.appSettings.updateCoupon({ code, durationInMonths, discountAmount, discountType });
          }

          this.$notify({ type: 'success', message: 'Coupon updated successfully!' });

          this.coupons = this.coupons.map((coupon) => {
            if (coupon.code === this.couponToSave.oldCode) {
              return {
                ...this.couponToSave,
                discountAmount:
                  this.discountType === 'flat'
                    ? Number(this.couponToSave.discountAmount)
                    : Number(this.couponToSave.discountAmount) / 100,
              };
            }

            return coupon;
          });

          this.returnToCouponsList();
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while updating the coupon.`,
            type: 'warning',
          });
        } finally {
          this.isSaving = false;
        }
      },
      async createCoupon() {
        this.isSaving = true;
        try {
          await controller.createCoupon(this.currentUser, this.couponToSave);

          if (this.couponToSave.type === 'interval') {
            const { code, durationInMonths, discountAmount, discountType } = this.couponToSave;
            await dao.appSettings.updateCoupon({ code, durationInMonths, discountAmount, discountType });
          }

          this.$notify({ type: 'success', message: 'Coupon created successfully!' });

          this.coupons = [
            {
              ...this.couponToSave,
              discountAmount:
                this.discountType === 'flat'
                  ? Number(this.couponToSave.discountAmount)
                  : Number(this.couponToSave.discountAmount) / 100,
              isNewUsersCoupon: this.couponToSave.description
                ? this.couponToSave.description.toLowerCase().includes('group:newusers')
                : false,
            },
            ...this.coupons,
          ];

          this.returnToCouponsList();
        } catch (error) {
          this.swalModal({
            title: 'Sorry',
            text: `An error has occurred while creating the coupon.`,
            type: 'warning',
          });
        } finally {
          this.isSaving = false;
        }
      },
      deleteCoupon(couponCode, plans) {
        this.swalModal({
          title: 'Delete coupon',
          html: `This will delete the coupon ${couponCode}. Are you sure?`,
          type: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes, go ahead',
          preConfirm: async () => {
            try {
              await controller.deleteCoupon(this.currentUser, couponCode, plans);
              await dao.appSettings.deleteCoupon(couponCode);
              this.coupons = this.coupons.filter((coupon) => coupon.code !== couponCode);
              this.$notify({ type: 'success', message: 'Coupon deleted successfully!' });
            } catch (error) {
              this.swalModal({
                title: 'Sorry',
                text: `An error has occurred while deleting the coupon.`,
                type: 'warning',
              });
            }
          },
        });
      },
      assignPlans() {
        this.couponToSave.plans = Object.values(config.paddleInfo.plans);

        const PLANS_LABELS = {
          premiumMonthly: 'Premium Monthly',
          premiumYearly: 'Premium Yearly',
          standardMonthly: 'Standard Monthly',
          standardYearly: 'Standard Yearly',
          basicMonthly: 'Basic Monthly',
          basicYearly: 'Basic Yearly',
        };

        const plansToAssign = {};

        Object.entries(config.paddleInfo.plans).forEach(([key, value]) => {
          plansToAssign[PLANS_LABELS[key]] = value;
        });

        this.plans = plansToAssign;
      },
    },
    mixins: [SwalModalMixin],
  };
</script>

<style lang="scss" scoped>
  .textarea {
    @apply w-64;
  }
</style>
